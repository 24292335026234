<template>
  <!-- <md-tabs md-sync-route class="md-primary" md-alignment="centered">
    <md-tab md-label="Home" to="/home"></md-tab>
    <md-tab md-label="About" to="/about"></md-tab>
    <md-tab class="md-left" md-label="logout" to="/"></md-tab>
  </md-tabs> -->

  <div class="navBar-container">
      <md-toolbar class="md-primary">
        <md-button class="md-icon-button" @click="toggleMenu" v-if="!menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <span class="md-title">IMA CMS</span>
      </md-toolbar>

      <md-drawer :md-active.sync="menuVisible" md-persistent="full">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="navigation">Navigation</span>

          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>

        <md-list>
          <md-list-item :to="'/home'">
            <md-icon>account_box</md-icon>
            <span class="md-list-item-text" >Home</span>
          </md-list-item>

          <md-list-item :to="'/landing'">
            <md-icon>home</md-icon>
            <span class="md-list-item-text" >Landing Page</span>
          </md-list-item>

          <md-list-item :to="'/course'">
            <md-icon>audiotrack</md-icon>
            <span class="md-list-item-text" >Course</span>
          </md-list-item>

          <md-list-item :to="'/masterclass'">
            <md-icon>hotel_class</md-icon>
            <span class="md-list-item-text" >Masterclass</span>
          </md-list-item>

          <md-list-item :to="'/faculty'">
            <md-icon>school</md-icon>
            <span class="md-list-item-text" >Faculty</span>
          </md-list-item>

          <md-list-item :to="'/media'">
            <md-icon>video_library</md-icon>
            <span class="md-list-item-text" >Media</span>
          </md-list-item>

          <md-list-item :to="'/files'">
            <md-icon>folder</md-icon>
            <span class="md-list-item-text" >Files</span>
          </md-list-item>

          <md-list-item :to="'/network'">
            <md-icon>wifi_tethering</md-icon>
            <span class="md-list-item-text" >Network</span>
          </md-list-item>

          <md-list-item :to="'/apply'">
            <md-icon>spellcheck</md-icon>
            <span class="md-list-item-text" >Apply</span>
          </md-list-item>

          <md-list-item :to="'/contact'">
            <md-icon>alternate_email</md-icon>
            <span class="md-list-item-text" >Contact</span>
          </md-list-item>

          <md-list-item :to="'/imprint'">
            <md-icon>gavel</md-icon>
            <span class="md-list-item-text" >Imprint</span>
          </md-list-item>

          <md-list-item :to="'/privacy'">
            <md-icon>privacy_tip</md-icon>
            <span class="md-list-item-text" >Privacy</span>
          </md-list-item>
<!--          <md-list-item @click="togglePages">-->
<!--            <md-icon>pageview</md-icon>-->
<!--            <span class="md-list-item-text" >Pages</span>-->
<!--          </md-list-item>-->
<!--          <md-list v-if="pagesVisible">-->
<!--              <md-list-item :to="'/pages/landing'">Landing Page</md-list-item>-->
<!--              <md-list-item>My Item 2</md-list-item>-->
<!--              <md-list-item>My Item 3</md-list-item>-->
<!--          </md-list>-->

          <md-list-item @click=logout>
            <md-icon>logout</md-icon>
            <span class="md-list-item-text" >Logout</span>
          </md-list-item>
        </md-list>
      </md-drawer>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
      name: 'navBar',
      data: () => ({
          menuVisible: false,
          pagesVisible: false
        }),
        methods: {
            ...mapActions([
                'logout'
            ]),
          toggleMenu () {
            this.menuVisible = !this.menuVisible
          },
          togglePages(){
              this.pagesVisible = !this.pagesVisible
          }
        }

    }

</script>
<style scoped>
.md-app {
    min-height: 350px;
    border: 1px solid rgba(#000, .12);
  }

  .md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
  }
.navigation{
  margin-left: 8px;
  font-size: 1.2em;
}
</style>
