<template>
  <div class="content">
    <p class="md-headline">{{ titleUser }}</p>
    <p v-if="descriptionProp" class="md-caption">{{ descriptionProp }}</p>
    <loading :active.sync="query"
             :can-cancel="true"
             :is-full-page="true"></loading>
    <table style="width: 100%; ">
      <tr>
        <td>
          <h2 class="md-title">Deutsch</h2>
          <md-field>
            <label>Überschrift</label>
            <md-input v-model="text.de.title"></md-input>
          </md-field>
          <md-field v-if="subheaderProp">
            <label>Subheader</label>
            <md-input v-model="text.de.subtitle"></md-input>
          </md-field>
          <md-field>
            <label>Text</label>
            <md-textarea v-model="text.de.content" md-autogrow></md-textarea>
          </md-field>
        </td>

        <td>
          <h2 class="md-title">English</h2>
          <md-field>
            <label>Header</label>
            <md-input v-model="text.en.title"></md-input>
          </md-field>
          <md-field v-if="subheaderProp">
            <label>Subheader</label>
            <md-input v-model="text.en.subtitle"></md-input>
          </md-field>
          <md-field>
            <label>Text</label>
            <md-textarea v-model="text.en.content" md-autogrow></md-textarea>
          </md-field>
        </td>
      </tr>
    </table>

    <md-button class="md-primary md-raised" @click="putText">Save</md-button>
    <md-button class="md-accent md-raised" @click="deleteText">Delete</md-button>
  </div>
</template>
<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/const.js'

export default {
  name: "TextEditComponent",

  props: {
    titleUser: String,
    titleInternProp: String,
    siteProp: String,
    subheaderProp: Boolean,
    descriptionProp: String
  },
  data() {
    return {
      config: config,
      text: null,
      query: false,

      newText: {
        titleIntern: this.titleInternProp,
        "de": {
          title: "",
          subtitle: "",
          content: ""
        },
        "en": {
          title: "",
          subtitle: "",
          content: ""
        },
        site: this.siteProp,
      },
    }
  },
  methods: {
    loadData: function () {
      const me = this
      me.query = true
      axios.get(me.config.SERVER_ADDRESS + '/text/name/' + this.titleInternProp)
          .then(function (response) {
            me.text = response.data[0]
            if (!me.text)
              me.text = me.newText
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    putText() {
      if (this.text._id == null)
        this.postText()
      this.query = true;
      let me = this;
      axios.put(me.config.SERVER_ADDRESS + '/text/id/' + me.text._id, me.text)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite()
            me.loadData()
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    postText() {
      // console.log("Posting Text now")
      // console.log(this.text)
      this.query = true;
      let me = this;
      axios.post(me.config.SERVER_ADDRESS + '/text', me.text)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite()
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    deleteText() {
      this.query = true;

      let me = this;
      axios.delete(me.config.SERVER_ADDRESS + '/text/' + me.text._id)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite();
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    resetSite: function () {
      this.text = null
      this.loadData()
    },

  },
  components: {
    Loading
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style scoped>
.content {
  margin: 2% 5% 0 5%;
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

td {
  padding: 1%;
  width: 50%;
}

.noMargin {
  margin: 0px;
}
</style>
