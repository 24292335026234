<template>
  <div class="content">
    <p class="md-headline">{{ titleUser }}</p>
    <p v-if="descriptionProp" class="md-caption">{{ descriptionProp }}</p>

    <loading :active.sync="query"
             :can-cancel="true"
             :is-full-page="true"></loading>
    <table style="width: 100%">
      <tr>
        <td>
          <h2 class="md-title">Deutsch</h2>
          <md-field>
            <label>Überschrift der Liste</label>
            <md-input v-model="list.de.title"></md-input>
          </md-field>
          <br/>
          <div class="md-layout md-alignment-space-around-left" v-if="dateProp">
            <md-icon>event</md-icon>
            <v-date-picker
                style="width: 90%"
                v-model="newElement.de.date"
            />
          </div>
          <md-field>
            <label>Elemente</label>
            <md-textarea v-model="newElement.de.subtitle" placeholder="Neues Element - Überschrift"
                         md-autogrow></md-textarea>
            <md-textarea v-model="newElement.de.text" placeholder="Neues Element - Text" md-autogrow></md-textarea>
            <md-button class="noMargin" @click="addElementToExisting('de')">Hinzufügen</md-button>
          </md-field>
          <div
              v-for="(element, i) in list.de.content"
              v-bind:key="i"
              style="width: 100%; padding: 5px 20px; display: flex; flex-direction: row; justify-content: space-between; border-bottom: 1px solid #999999"
          >
            <span>
              <i v-if="dateProp">{{ niceDates(element.date) }}</i>
              <br/>
              Nr:
              <input
                  v-model="element.nr"
                  style="width: 50px"
                  type="number"
              />
              <br/>
              <b>{{ element.subtitle }}</b>
              {{ element.text }}
            </span>
            <span @click="list.de.content.splice(i, 1)">
              <md-icon>delete</md-icon>
            </span>
          </div>
        </td>

        <td>
          <h2 class="md-title">English</h2>
          <md-field>
            <label>Header</label>
            <md-input v-model="list.en.title"></md-input>
          </md-field>
          <br/>
          <div class="md-layout md-alignment-space-around-left" v-if="dateProp">
            <md-icon>event</md-icon>
            <v-date-picker
                style="width: 90%"
                v-model="newElement.en.date"
            />
          </div>
          <md-field>
            <label>Elements</label>
            <md-textarea v-model="newElement.en.subtitle" placeholder="New Element - Heading" md-autogrow></md-textarea>
            <md-textarea v-model="newElement.en.text" placeholder="New Element - Text" md-autogrow></md-textarea>
            <md-button class="noMargin" @click="addElementToExisting('en')">Add</md-button>
          </md-field>
          <div
              v-for="(element, i) in list.en.content"
              v-bind:key="i"
              style="width: 100%; padding: 5px 20px; display: flex; flex-direction: row; justify-content: space-between; border-bottom: 1px solid #999999"
          >
            <span>
              <i v-if="dateProp">{{ niceDates(element.date) }}</i>
              <br/>
              Nr:
              <input
                  v-model="element.nr"
                  style="width: 50px"
                  type="number"
              />
              <br/>
              <b>{{ element.subtitle }}</b>
              {{ element.text }}
            </span>
            <span @click="list.en.content.splice(i, 1)">
              <md-icon>delete</md-icon>
            </span>
          </div>
        </td>
      </tr>
    </table>
    <md-button class="md-primary md-raised" @click="putList()">Save</md-button>
    <md-button class="md-accent md-raised" @click="deleteList()">Delete</md-button>

  </div>
</template>
<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/const.js'

export default {
  name: "ListEditComponent",

  props: {
    titleUser: String,
    titleInternProp: String,
    siteProp: String,
    subheaderProp: Boolean,
    descriptionProp: String,
    dateProp: Boolean
  },
  data() {
    return {
      config: config,
      dateOptions: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},

      query: false,
      currentListName: '',


      newListDialogActive: false,
      newListCancelDialogActive: false,
      deleteListDialogActive: false,

      list: [],
      newList: {
        titleIntern: this.titleInternProp,
        "de": {
          title: "",
          content: []
        },
        "en": {
          title: "",
          content: []
        },
        site: this.siteProp,
      },
      newElement: {
        "de": {
          subtitle: '',
          text: '',
          date: new Date()
        },
        "en": {
          subtitle: '',
          text: '',
          date: new Date()
        }
      },
    }
  },
  methods: {
    niceDates: function (date) {
      return new Date(date).toLocaleDateString('en', this.dateOptions)
    },
    loadDataList: function () {
      const me = this
      axios.get(me.config.SERVER_ADDRESS + '/list/name/' + me.titleInternProp)
          .then(function (response) {
            me.list = response.data
            if (me.list[0]) {
              me.list = response.data[0]
            } else {
              me.list = me.newList
            }
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          });
    },
    deleteList() {
      this.query = true;

      let me = this;
      axios.delete(me.config.SERVER_ADDRESS + '/list/' + me.list._id)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite();
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    addElementToExisting(language) {
      switch (language) {
        case 'de':
          this.list.de.content.push(this.newElement.de);
          this.newElement.de = {
            subtitle: '',
            text: ''
          };
          break;
        case 'en':
          this.list.en.content.push(this.newElement.en);
          this.newElement.en = {
            subtitle: '',
            text: ''
          };
          break;
      }
    },
    postNewList() {
      this.query = true;
      let me = this;
      // console.log(JSON.stringify(me.newList));
      axios.post(me.config.SERVER_ADDRESS + '/list', me.newList)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite()
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    putList() {
      if (!this.list._id) {
        this.postNewList()
        return
      }
      this.query = true;
      let me = this;
      // console.log(me.list[me.index]._id)
      // console.log(JSON.stringify(me.list[me.index]))
      axios.put(me.config.SERVER_ADDRESS + '/list/id/' + me.list._id, me.list)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite()
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    resetSite: function () {

      this.query = false
      this.currentListName = ''


      this.newListDialogActive = false
      this.newListCancelDialogActive = false
      this.deleteListDialogActive = false

      this.list = []
      this.newList = {
        titleIntern: this.titleInternProp,
        "de": {
          title: "",
          content: []
        },
        "en": {
          title: "",
          content: []
        },
        site: this.siteProp,
      }
      this.newElement = {
        titleIntern: this.titleInternProp,
        "de": {
          subtitle: '',
          text: ''
        },
        "en": {
          subtitle: '',
          text: ''
        }
      }
      this.loadDataList()
    },

  },
  components: {
    Loading
  },
  mounted() {
    this.loadDataList()
  }
}
</script>
<style scoped>
.content {
  margin: 2% 5% 0 5%;
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

td {
  padding: 1%;
  width: 50%;
}

.noMargin {
  margin: 0px;
}
</style>
